const storage = {
    get(storageItem) {
        const storageData = JSON.parse(sessionStorage.getItem(storageItem));
        if (storageData && storageData.expiration && storageData.expiration <= new Date()) {
            this.remove();
            return null;
        }
        return storageData;
    },
    set(storageItem, data) {
        sessionStorage.setItem(storageItem, JSON.stringify(data));
    },
    remove() {
        sessionStorage.clear()
    },
}

export default storage;
