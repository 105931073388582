<template>
  <VotePage_2 v-if="settings && typeof settings.design != 'undefined' && settings.design === 2" :settings="settings"/>
  <VotePage :settings="settings" v-else-if="settings"/>
</template>

<script>
import VotePage from "@/pages/Custom/VotePage.vue";
import VotePage_2 from "@/pages/Custom/VotePage_2.vue";
import storage from "@/services/storage";

export default {
  name: 'VotesPage',
  components: {VotePage, VotePage_2},
  data() {
    return {
      settings: null,
    }
  },
  mounted() {
    this.settings = storage.get('settings')
  }
}
</script>
