import {reactive} from "vue";
import {io} from "socket.io-client";

export const state = reactive({
    connected: false,
    fooEvents: [],
    barEvents: []
});

const URL = await process.env.VUE_APP_SOCKET_URL;


export const socket = io(URL, {
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 2000,
    reconnectionDelayMax: 5000,
    secure: true
});

socket.on("connect", () => {
    state.connected = true;
});

socket.on("disconnect", () => {
    state.connected = false;
});

document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && !state.connected) {
        socket.connect();
    }
});

