<template>
  <div
      class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 fhf7t426 f8m0rgwh gndfcl4n light">
    <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 dblt22a0">
      <div class="dupc5dfw bcr6az0x landing-title" aria-label="">{{ headerText }}</div>
    </div>
    <img class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 r2u2pyhj"
         :src="photo" width="250" alt="" aria-label="">
    <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7 r2u2pyhj"
    style="text-align: center;width:70%;margin-left: auto;margin-right: auto;">
      <div class="enbbiyaj e1gr2w1z hp667wtd" aria-label="">{{ text }}</div>
    </div>
    <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk o2es7gts i2cterl7 iyjcf3gk"
         v-on:click="confirmEvent" v-on:keyup.enter="confirmEvent">
      <div role="button" tabindex="0" class="_1M6AF _3QJHf">
        <div
            class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd p357zi0d dnb887gk gjuq5ydh i2cterl7 ac2vgrno sap93d0t gndfcl4n">
          <div class="tvf2evcx m0h2a7mj lb5m6g5c j7l1k36l ktfrpxia nu7pwgvd dnb887gk gjuq5ydh i2cterl7">
            <span>{{ buttonText }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhoneComponent',

  props: {
    event: {
      type: Function,
      required: true,
    },
    headerText: {
      type: String,
      required: false,
    },
    photo: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },

  methods: {
    confirmEvent() {
      this.event();
    },
  },
}
</script>
